<template>
  <v-toolbar :flat="computedFlat" v-bind="$attrs" v-on="$listeners">
    <v-app-bar-nav-icon v-show="visibleAppBarNavIcon"></v-app-bar-nav-icon>

    <v-toolbar-title v-if="hasSlot('toolbar-title')">
      <slot name="toolbar-title"></slot>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <slot name="default"></slot>
  </v-toolbar>
</template>

<script>
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseToolbar",
  inheritAttrs: false,
  mixins: [slottableMixin],
  props: {
    visibleAppBarNavIcon: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    computedFlat() {
      return this.flat === undefined ? true : this.flat;
    }
  }
};
</script>
